import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { media } from 'style/style-utils';

export const Box = styled.div`
  flex: 25%;
  text-align: center;

  ${media.tablet`
    flex: 50%;
  `};

  ${media.phone`
    margin-top: ${props => ((props.position === 2 || props.position === 3) ? '30px' : '0')}
  `};
`;

const TopImage = styled.img`
  width: 55%;
  display: block;
  margin: auto;
`;

const ContentWrapper = styled.div`
  min-height: 180px;

  ${media.phone`
    min-height: 0px;
  `}
`;

const Title = styled.div`
  font-family: ${props => props.theme.fonts.text}, sans-serif;
  font-weight: bold;
  margin: 15px 0 20px 0;

  ${media.phone`
    margin: 10px 0 10px 0;
  `}
`;

const City = styled.div`
  font-family: ${props => props.theme.fonts.text}, sans-serif;
  font-weight: 400;
  font-size: 0.9em;
  margin: 5px 0;
`;

const Cities = styled.div`
  ${media.phone`
    display: none;
  `}
`;

const BottomImage = styled('img')`
  margin: auto;
  border-radius: 10px;
  box-shadow: 0px 0px 5px 5px white;
  width: 65%;

  ${media.tablet`
    width: 60%;
  `};

  ${media.phone`
    width: 80%;
  `};
`;

const StyledLink = styled(Link)`
  color:inherit;
  text-decoration: none;
  margin: auto;
  flex: 20%;
  text-align: center;

  & > img:last-of-type {
    background-color: #cfcfcf;
  }

  &:hover > img:last-of-type {
    background-color: #fec931;
  }

  ${media.tablet`
    flex: 50%;
    margin-top: 10px;
  `};

  ${media.phone`
    margin-top: ${props => ((props.position === 2 || props.position === 3) ? '30px' : '0')}
  `};
`;

const HomeIslandCard = ({ island,  position }) => {
  const cities = island.associatedStores.map((cityName) => {
    return <City key={cityName}>{cityName}</City>
  });

  return (
    <StyledLink to={`/${island.uid}`} position={position}>
        <TopImage src={island.logo.url} />
        <ContentWrapper>
          <Title>{island.islandName.toUpperCase()}</Title>
          <Cities>{cities}</Cities>
        </ContentWrapper>
        <BottomImage src={island.cardImage.url} />
    </StyledLink>
  );
}

export default HomeIslandCard;
