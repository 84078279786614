import { H1 } from 'components/common/Heading';
import { media } from 'style/style-utils';
import styled from 'styled-components'

const BottomBannerHeading = styled(H1)`
  text-align: center;
  font-size: 25px;
  padding: 0 2%;
  ${media.phone`font-size: 0.8em`}
`;

export default BottomBannerHeading;
