import React from 'react';
import IslandCard from './IslandCard';
import styled from 'styled-components';
import { media } from 'style/style-utils';

const Flex = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: stretch;
  max-width: 1000px;
  margin: auto;
`;

const Sep = styled.div`
  position: relative;
  bottom: 0;
  height: 400px;
  width: 5px;
  background: linear-gradient(white, white, rgb(0, 0, 0, 0));

  ${media.tablet`
    display: none;
  `}
`;

const IslandCards = (props) => {
  const { islands } = props;
  const islandsCardsMarkups = islands.map((island, index) => {
    return (
      <React.Fragment key={island.islandName}>
        <IslandCard island={island} position={index} />
        {(islands.length - 1 === index ? null : <Sep/>)}
      </React.Fragment>
    );
  });

  return (
    <Flex>
      {islandsCardsMarkups}
    </Flex>
  );
}

export default IslandCards;
