import GlobalStyle from 'style/global-styles';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { ThemeProvider } from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import theme from 'style/theme';
import Header from 'components/header/Header';
import Footer from 'components/footer/Footer';
import favicon from 'assets/favicon-32.ico';

class HomeLayout extends Component {
  render() {
    const { children } = this.props;

    return (
      <StaticQuery
        query={graphql`
          {
            prismicFooter {
              data {
                legal {
                  html
                }
                text {
                  text
                }
              }
            }
            prismicHomepage {
              data {
                logo {
                  url
                }
                seo_meta_title {
                  text
                }
                seo_meta_description {
                  text
                }
                seo_meta_keywords {
                  text
                }
              }
            }
          }
        `}
        render={data => {
          const homePageData = data.prismicHomepage.data;
          const seoTitle = homePageData.seo_meta_title.text;
          const seoDescription = homePageData.seo_meta_description.text;
          const seoKeywords = homePageData.seo_meta_keywords.text;

          const footerText = data.prismicFooter.data.text.text;
          const footerLegal = data.prismicFooter.data.legal.html;

          const logoImage = homePageData.logo;

          return (
            <>
              <GlobalStyle/>
              <Helmet
                title={seoTitle}
                meta={[
                  { name: 'description', content: seoDescription },
                  { name: 'keywords', content: seoKeywords },
                ]}
                link={[
                    { rel: 'shortcut icon', type: 'image/x-icon', href: `${favicon}` }
                ]}
              />
              <ThemeProvider theme={theme}>
                <>
                  <Header logoImage={logoImage}/>
                  {children}
                  <Footer text={footerText} legal={footerLegal} />
                </>
              </ThemeProvider>
            </>
          )
        }}
      />
    );
  }
}

HomeLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default HomeLayout;
