import React from 'react';
import { graphql } from 'gatsby';
import { get, set, reduce, pipe } from 'lodash/fp';
import Layout from 'components/layouts/Layout';
import Banner from 'components/home/Banner';
import IslandCards from 'components/island/IslandCards';
import BottomBannerHeading from 'components/home/BottomBannerHeading';
import Space from 'components/common/Space';
import CardsBackground from 'components/island/CardsBackground';
import SectionWrapper from 'components/common/SectionWrapper';
import TriangleShapeSeparator from 'components/home/TriangleShapeSeparator';
import styled from 'styled-components';
import { media } from 'style/style-utils';

function flatIslandsData(islandsData) {
  return reduce((acc, islandData) => {
    const getUid = get('island.document.uid');
    const setUid = set('uid')(getUid(islandData));

    const getIslandName = get('island.document.data.island_name.text');
    const setIslandName = set('islandName')(getIslandName(islandData));

    const getCardImage = get('island.document.data.card_image');
    const setCardImageUrl = set('cardImage')(getCardImage(islandData));

    const getLogo = get('island.document.data.logo');
    const setLogo = set('logo')(getLogo(islandData));

    const getAssociatedStores = get('island.document.data.associated_stores');
    const associatedStoreNames = reduce((acc, associatedStoreData) => {
      return [...acc, get('associated_store.document.data.store_name.text')(associatedStoreData)];
    })([])(getAssociatedStores(islandData));
    const setAssociatedStores = set('associatedStores')(associatedStoreNames);

    const island = pipe(
      setUid,
      setIslandName,
      setAssociatedStores,
      setCardImageUrl,
      setLogo
    )({});

    return [...acc, island];
  })([])(islandsData);
}

const Background = styled.div`
  margin-top: 67px;
  position: relative;
  background-color: #e7e7e7;

  ${media.phone`
    margin-top: 0;
    background-color: white;
  `}
`;

const ColorLineWrapper = styled.div`
  position: relative;
  top: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;

  ${media.phone`
    display: none;
  `}
`;

const ColorLine = styled.div`
  height: 6px;
  width: 100%;
  background-color: ${props => props.color};
`;

const IndexPage = ({ data }) => {
  const homePageData = get('prismicHomepage.data')(data);
  const islandsData = flatIslandsData(get('islands')(homePageData));
  const bannerText = get('top_banner_text.text')(homePageData);
  const bannerImage = get('bannerImage')(data);
  const mobileBannerImage = get('mobileBannerImage')(data);
  const bannerPersoImage = get('bannerPersoImage')(data);

  const colors = ['#95ac16', '#116aae', '#676664', '#de091f', '#8f3c8a', '#ef9109', '#e0137a', '#1d928c'];
  const linesMarkup = colors.map((color, index) => <ColorLine key={index} color={color}/>)

  return (
    <Layout>

        <Background>
          <ColorLineWrapper>
            {linesMarkup}
          </ColorLineWrapper>
          <SectionWrapper>
            <Banner
              text={bannerText}
              mobileBannerImage={mobileBannerImage}
              bannerImage={bannerImage}
              bannerPersoImage={bannerPersoImage}
            />
          </SectionWrapper>
        </Background>

        <SectionWrapper>

        <TriangleShapeSeparator/>

        <Space space={55} phone={12}/>

        <BottomBannerHeading as='h1'>
          Réseaux de magasins présents en Guadeloupe et Martinique sous la marque "Diamant Distribution", et à la Réunion et Mayotte sous la marque "Diam Loisirs"
        </BottomBannerHeading>

        <Space space={40} phone={12}/>

        </SectionWrapper>

      <span id="catalogues"></span>

      <CardsBackground>
        <SectionWrapper>
            <IslandCards islands={islandsData}/>
        </SectionWrapper>
      </CardsBackground>

      <Space space={73} phone={10}/>

    </Layout>
  );
}

export const query = graphql`
  {
    bannerImage: file(relativePath: { eq: "map-banner.jpg" }) {
      childImageSharp {
        sizes(maxWidth: 906, quality: 100) {
          ...GatsbyImageSharpSizes_withWebp_tracedSVG
        }
      }
    }
    bannerPersoImage: file(relativePath: { eq: "perso.png" }) {
      childImageSharp {
        sizes(maxWidth: 229, quality: 100) {
          ...GatsbyImageSharpSizes_withWebp_tracedSVG
        }
      }
    }
    mobileBannerImage: file(relativePath: { eq: "home-mobile-hero-image.png" }) {
      childImageSharp {
        sizes(maxWidth: 576, quality: 100) {
          ...GatsbyImageSharpSizes_withWebp_tracedSVG
        }
      }
    }
    prismicHomepage {
      data {
        top_banner_text {
          text
        }
        primary_title {
          text
        }
        islands {
          island {
            document {
              ... on PrismicIsland {
                uid
                data {
                  logo {
                    url
                  }
                  card_image {
                    url
                  }
                  island_name {
                    text
                  }
                  associated_stores {
                    associated_store {
                      document {
                        ... on PrismicStore {

                        data {
                          store_name {
                            text
                          }
                        }
                      }
                      }
                    }
                  }
                }
              }
              }
          }
        }
      }
    }
  }
`;

export default IndexPage;
