import React from 'react';
import styled from 'styled-components';
import { media } from 'style/style-utils';

const Wrapper = styled.a`
  display: block;
  width: 100%;
  text-align: center;
  cursor: pointer;
  
  ${media.phone`
    display: none;
  `}
`;

const StyledSvg = styled.svg`
  width: 50px;
  height: 30px;
  fill: #e7e7e7;
  margin-top: -1;
`;

const ShapeSeparator = () => {
  return (
    <Wrapper href="#catalogues">
      <StyledSvg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 50 30"
        preserveAspectRatio="none"
      >
        <path d="M 0 0 L 25 25 L50 0 Z"/>
      </StyledSvg>
    </Wrapper>
  );
}

export default ShapeSeparator;
