import React from 'react';
import styled from 'styled-components';
import { H1 } from 'components/common/Heading';
import { media, customMedia } from 'style/style-utils';
import Img from 'gatsby-image';

const Content = styled.div`
  max-width: ${props => props.theme.contentWidth};
  margin: auto;
`;

const Wrapper = styled.div`
  position: relative;
`;

const MapAndHeadingWrapper = styled.div`
  width: 905px;
  margin: auto;
  padding-top: 35px;
  padding-bottom: 50px;

  ${customMedia(906)`
    width: 100%;
  `}

  ${media.phone`
    padding-top: 20px;
    padding-bottom: 0;
  `}
`;

const MapImageWrapper = styled.div`
  width: 100%;
  margin: auto;

  ${media.phone`
    display: none;
  `}
`;

const BannerHeading = styled(H1)`
  text-align: right;
  font-weight: 800;
  color: #333333;
  padding: 0 0 20px 0;
  margin: 0;

  ${customMedia(962)`
    font-size: 1.9em;
  `}
  ${customMedia(834)`
    font-size: 1.8em;
  `}
  ${customMedia(834)`
    font-size: 1.7em;
  `}
  ${media.tablet`
    font-size: 1.4em;
  `}
  ${customMedia(646)`
    font-size: 1.2em;
  `}
  ${media.phone`
    text-align: center;
  `}

`.withComponent('p');

const PersoImageWrapper = styled.div`
  z-index: 1;
  position: absolute;
  bottom: 0;
  margin: -39px 0 0 73px;
  width: 229px;

  ${customMedia(1100)`
    margin: 0 0 0 20px;
  `}

  ${customMedia(870)`
    margin: 0;
    width: 210px;
  `}

  ${media.tablet`
    margin: 0 0 0 10px;
    width: 180px;
  `}

  ${media.phone`
    display: none;
  `}
`;

const PhoneImageWrapper = styled.div`
  width: 100%;
  display: none;
  ${media.desktop`display: none;`}
  ${media.tablet`display: none;`}
  ${media.phone`display: block;`}
`;

// const PhoneImage = styled.img`
//   width: 100%;
//   display: none;
//   ${media.desktop`display: none;`}
//   ${media.tablet`display: none;`}
//   ${media.phone`display: block;`}
// `;

const Banner = ({ text, mobileBannerImage, bannerImage, bannerPersoImage }) => {
  return (
    <Content>
      <Wrapper>
        <PersoImageWrapper>
          <Img sizes={bannerPersoImage.childImageSharp.sizes}/>
        </PersoImageWrapper>
        {/* <Perso src={perso}/> */}
        <MapAndHeadingWrapper>
          <BannerHeading as='h1'>{text}</BannerHeading>
          <MapImageWrapper>
            <Img sizes={bannerImage.childImageSharp.sizes}/>
          </MapImageWrapper>
          {/* <MapImage src={mapBanner}/> */}
        </MapAndHeadingWrapper>
        {/* <PhoneImage src={mobileHero} /> */}
        <PhoneImageWrapper>
          <Img sizes={mobileBannerImage.childImageSharp.sizes} />
        </PhoneImageWrapper>
      </Wrapper>
    </Content>
  );
};

export default Banner;
